<template>
  <v-sheet class="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <!-- {{ all }} -->
      <v-col md="3" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label me-0"
                  style="font-weight: 600; font-size: 18px"
                >
                  <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="blue darken-4" dark>person</v-icon>
                  </VAvatar>
                  All</v-list-item-title
                >
              </v-list-item-content>
              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600">{{
                    all
                  }}</v-chip>
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid #ef9a9a">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="red" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  This Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#F44336" style="font-size: 18px; color: white; font-weight: 600">{{
                    month
                  }}</v-chip>
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid orange">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="orange lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="orange" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  Last Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="orange" style="font-size: 18px; color: #fff; font-weight: 600">{{
                    lastMonth
                  }}</v-chip></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="">
        <v-card class="mx-auto" style="border: 0.5px solid #81c784">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label d-flex align-center"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="green lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  <span>Last 4 Month<br /> </span>
                </v-list-item-title>
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading"
                  ><v-chip color="green" style="font-size: 18px; color: white; font-weight: 600">{{
                    fourMonth
                  }}</v-chip></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text class="px-0 p">
      <v-row>
        <v-col md="2" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter By Employee</label
          >
          <AutoCompleteInput
            class="mt-3"
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="employee"
            id="segment"
            item-text="display_name"
            v-model="search_employees"
            item-value="id"
            placeholder="Select Employee"
          ></AutoCompleteInput>
        </v-col>
        <v-col md="2" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter by Leave Type</label
          >
          <AutoCompleteInput
            class="mt-3"
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            v-model="search_type"
            :items="['Paid', 'Unpaid']"
            item-text="name"
            placeholder="Select Leave Type"
          >
          </AutoCompleteInput>
        </v-col>
        <v-col md="2" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter by Month</label
          >
          <AutoCompleteInput
            hide-details
            class="mt-3"
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="months"
            id="segment"
            v-model="search_month"
            item-text="name"
            item-value="value"
            placeholder="Select Month"
          ></AutoCompleteInput>
        </v-col>
        <v-col md="4" class="mt-5">
          <v-row>
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue darken-4"
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="searchData"
                    depressed
                    tile
                    class="ml-2 white--text me-1"
                    >Filter</v-btn
                  >
                </template>
                <span>Filter</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    v-on:click="resetEmployee"
                    tile
                    class="ml-2 white--text me-1"
                    >Reset Filter</v-btn
                  >
                </template>
                <span>Reset Filter</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    class="ml-2 white--text"
                    >Export</v-btn
                  >
                </template>
                <span>Export</span>
              </v-tooltip> -->
            </v-flex>
          </v-row>
        </v-col>
        <v-col class="mt-5 justify-content-end">
          <v-flex class="d-flex justify-content-end">
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="blue darken-4"
                      depressed
                      tile
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="white--text"
                    >
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item
                  draggable
                  @dragstart="dragstart(index)"
                  @dragover="dragover(index)"
                  @dragend="dragEnd"
                  v-for="(item, index) in leaveTableHeading"
                  :key="item.title + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move"
                >
                  <div class="d-flex flex-row">
                    <v-checkbox
                      :ripple="false"
                      hide-details
                      color="#0d47a1"
                      v-model="item.status"
                      class="m-0"
                    ></v-checkbox>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-icon>drag_indicator</v-icon>
                  </div>
                  <v-divider
                    v-if="index < leaveTableHeading.length - 1"
                    class="mt-1 mb-0"
                    style="width: 100%"
                  ></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- <pre>{{ customers }}</pre> -->
    <v-simple-table fixed-header class="bt-table table_height_leave">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="heading in leaveTableHeading"
              :key="heading.title"
              style="background-color: #f5f5f5"
            >
              <div v-if="heading.status">{{ heading.title }}</div>
            </th>
          </tr>
        </thead>

        <tbody v-if="!pageLoading">
          <template v-if="customers.length">
            <tr
              v-for="(row, index) in customers"
              :key="'key_' + index"
              :class="index % 2 === 0 && 'blue lighten-5'"
              class="leave-listing-tr"
            >
              <td v-for="(th, index) in leaveTableHeading" :key="'key_' + index">
                <v-chip
                  color="#4CAF50"
                  v-if="getColValue(th.title) === 'leave_type' && th.status"
                  style="color: white; font-weight: 600"
                >
                  {{ row.leaveType }}

                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </v-chip>
                <v-chip
                  color="#4CAF50"
                  v-if="getColValue(th.title) === 'leave_day' && th.status"
                  style="color: white; font-weight: 600"
                >
                  {{ row.leaveDay }}

                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </v-chip>

                <div v-else-if="getColValue(th.title) === 'action' && th.status">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        class="mx-2"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click.stop.prevent="routeToUpdate(row.id)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click.stop.prevent="deleteConfirm(row)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
                <div v-else-if="getColValue(th.title) === 'added_by' && th.status">
                  <v-chip class="ma-2" color="#0D479F" text-color="white">
                    <v-icon medium class="me-1">mdi-account-circle</v-icon>
                    {{row.added_by?.display_name}}
                 
                  
                  </v-chip>
                </div>

                <div v-else-if="getColValue(th.title) === 'reason' && th.status">
                  <template v-if="row.reason">
                    <div class="text-truncate" style="width: 250px; text-transform: capitalize">
                    {{ row.reason }}
                  </div>
                  <span
                    v-if="row && row.reason && row.reason.length > 20"
                    style="color: #24326d; cursor: pointer; font-size: 12px"
                    @click="remarkPop(row.reason)"
                    >Read More</span
                  >
                  </template>
                  <template v-else>
                    No Reason

                  </template>
                 
                </div>

                <div v-else-if="getColValue(th.title) === 'duration' && th.status">
                  <v-chip class="ma-2" color="#0D479F" text-color="white">
                    <ValueTemplate
                      class="text-capitalize"
                      v-model="row[getColValue(th.title)]"
                      :title="row[getColValue(th.title)]"
                    ></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value }} -->
                  </v-chip>
                </div>

                <div v-else-if="getColValue(th.title) === 'leave_day' && th.status">
                  <v-chip
                    small
                    style="color: white; font-weight: 600; text-transform: capitalize"
                    color="success"
                    outlined
                  >
                    <ValueTemplate
                      v-model="row[getColValue(th.title)]"
                      :title="row[getColValue(th.title)]"
                    ></ValueTemplate>
                    <!-- {{ row[getColValue(th.title)]?.value }} -->
                  </v-chip>
                </div>

                <div v-else-if="getColValue(th.title) === 'start_date' && th.status">
                  {{formatedate(row.start_date)}}
                 
                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>

                <div v-else-if="getColValue(th.title) === 'end_date' && th.status">
                  {{formatedate(row.end_date)}}
                
                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>

                <div v-else-if="getColValue(th.title) === 'employee' && th.status">
                  {{row?.employee?.display_name}}
                  <!-- <ValueTemplate
                    class="text-capitalize"
                    v-model="row.employee?.display_name"
                    :title="row?.employee?.display_name"
                  ></ValueTemplate> -->
                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>
                <div v-else-if="getColValue(th.title) === 'added_on' && th.status">
                  {{ formatedateTimes(row.added_at) }}

                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no leave at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="idr">
            <td v-for="idk in 9" :key="idk">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="customers.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Reason</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img
                width="70"
                height="70"
                src="https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png"
              />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>

    <OrderTemplate
      v-model="order"
      :customer-id="customerId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    >
    </OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>

    <DeleteTemplate
      type="leave"
      :delete-text="deleteText"
      delete-note="All leave of this employee will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getLeave()"
    >
    </DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { GET_LEAVE, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import ValueTemplate from "@/view/components/ValueTemplate";
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { QUERY } from "@/core/services/store/request.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SearchEventBus } from "@/core/lib/search.lib";

import { mapGetters } from "vuex";
import moment from "moment-timezone";

import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";

export default {
  name: "customer-listing",
  title: "Listing Customer",
  data() {
    return {
      leaveTableHeading: [
        // { title: "ACTION", status: true },
        { title: "EMPLOYEE", status: true },
        { title: "LEAVE TYPE", status: true },
        { title: "LEAVE DAY", status: true },
        { title: "START DATE", status: true },
        { title: "END DATE", status: true },
        { title: "DURATION", status: true },
        { title: "REASON", status: true },
        { title: "ADDED ON", status: true },
        { title: "ADDED BY", status: true },
      ],
      months: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
      leaveTableData: [
        {
          code: "LDR35",
          action: { value: "", key: "ACTION" },
          employee: { value: "ABINASH", key: "EMPLOYEE" },
          leave_type: { value: "PAID", key: "LEAVE TYPE" },
          leave_day: { value: "FULL DAY", key: "LEAVE DAY" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          end_date: { value: "28/08/2023", key: "END DATE" },
          duration: { value: 1, key: "DURATION" },
          reason: { value: "REASON", key: "REASON" },
          added_on: { value: "28/08/2023", key: "ADDED ON" },
          added_by: { value: "ALAN HENG", key: "ADDED BY" },
        },
        {
          code: "NDF05",
          action: { value: "", key: "ACTION" },
          employee: { value: "FROZEN YOGURT", key: "EMPLOYEE" },
          leave_type: { value: "PAID", key: "LEAVE TYPE" },
          leave_day: { value: "FULL DAY", key: "LEAVE DAY" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          end_date: { value: "28/08/2023", key: "END DATE" },
          duration: { value: 1, key: "DURATION" },
          reason: { value: "REASON", key: "REASON" },
          added_on: { value: "28/08/2023", key: "ADDED ON" },
          added_by: { value: "ALAN HENG", key: "ADDED BY" },
        },
      ],
      isExpanded: false,
      text: null,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      dialog: false,
      filterType: "all",
      employee: [],
      filterDateRange: null,
      speedDial: [],
      lastMonth: null,
      search_employees: null,
      search_employee: null,
      search_type: null,
      search_month: null,

      month: null,
      all: null,
      whatsappPromotional: false,
      customers: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      fourMonth: null,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
      dragStartIndex: null,
      dragOverIndex: null,
    };
  },
  watch: {
    leaveTableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getLeave();
    },
    listingSearch() {
      this.getLeave();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getLeave();
      }
    },
  },
  methods: {
    formatedate(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    searchData() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: "/employee/leave",
          data: {
            employee: _this.search_employees,
            month: _this.search_month,
            leaveType: _this.search_type,
            current_page: this.currentPage,
          },
        })
        .then((data) => {
          _this.customers = data.data;
          _this.totalrows = data.totalrows;
          _this.totalPages = data.totalPages;
          _this.currentPage = data.current_page;
          // console.log(_this.employeeList)
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    resetEmployee() {
      this.search_employees = null;
      this.search_month = null;
      this.search_type = null;

      this.getLeave();
    },
    getEmployee() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: `get-employee-list`,
        })
        .then((data) => {
          this.employee = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {});
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YY hh:mm A");
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    remarkPop(reason) {
      this.text = reason;
      this.dialog = true;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.leaveTableHeading[this.dragStartIndex];
      this.leaveTableHeading.splice(this.dragStartIndex, 1);
      this.leaveTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    /*  addElipses(text, words) {
      if (text.length > 5) {
        if (words === 0) {
          return { text: text.slice(0, text.length), readmore: false };
        } else {
          return { text: text.slice(0, words) + "...", readmore: true };
        }
      } else {
        return { text: text.slice(0, text.length), readmore: false };
      }
    }, */
    getAllCollectedVouchers() {},
    // changeDatePicker() {
    //   if (this.filterDateRange[0] && this.filterDateRange[1]) {
    //     this.getLeave()
    //   }
    // },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getLeave();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, code) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = code;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, code) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = code || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "leave-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "leave-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ code, id }) {
      this.deleteText = code;
      this.deleteEndpoint = `leave/${id}`;
      this.deleteDialog = true;
    },
    async getLeave(search = null) {
      try {
        let form = {
          current_page: this.currentPage,
          // search: this.listingSearch,
          filter_type: this.filterType,
          search,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const {
          data,
          totalPages,
          fourMonth,
          showingFrom,
          showingTo,
          totalRows,
          count,
          month,
          lastMonth,
        } = await GET_LEAVE(form);
        this.customers = data;
        console.log(data, "data");
        this.all = count;
        this.month = month;
        this.lastMonth = lastMonth;
        this.fourMonth = fourMonth;

        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getLeave();
    this.getEmployee();
    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Leave");
    SearchEventBus.$on("start-search", (argument) => {
      this.searchEnabled = false;
      this.searchParam = argument;
      if (this.searchParam) {
        this.searchEnabled = true;
      }
      this.getLeave(argument);
    });
    SearchEventBus.$emit("search-template", true);
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    SearchTemplate,
    SelectInput,
    Dialog,
    ValueTemplate,
    AutoCompleteInput,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.advace_list_menu {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: space-between;
}

.table_height_leave .v-data-table__wrapper {
  height: calc(100vh - 360px);
}

::-webkit-scrollbar {
  height: 10px;
}
.leave-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.leave-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
